import React, { Component } from 'react'
import { Link, withPrefix } from 'gatsby'
import classnames from 'classnames'
import { ChevronDown } from 'react-feather'
import Jump from 'react-reveal/Jump'
import Sound from './LoadableSound.js'
import styles from './AudioExamples.module.sass'

import imgPlay from '../images/headphonesIcon.svg'
import imgStop from '../images/controls-stop.svg'

const sounds = [
  { name: 'en-US: Isabella', url: withPrefix('/voices/en-US-Wavenet-F.mp3') },
  { name: 'en-US: Wyatt', url: withPrefix('/voices/en-US-Wavenet-J.mp3') },
  { name: 'en-GB: Emma', url: withPrefix('/voices/en-GB-Wavenet-A.mp3') },
  { name: 'en-GB: Harry', url: withPrefix('/voices/en-GB-Wavenet-B.mp3') },
  { name: 'en-AU: Amelia', url: withPrefix('/voices/en-AU-Wavenet-C.mp3') },
  { name: 'en-AU: Jack', url: withPrefix('/voices/en-AU-Wavenet-B.mp3') },

  { name: 'es-ES: Luna', url: withPrefix('/voices/es-ES-Standard-A.mp3') },
  { name: 'es-ES: Carlos', url: withPrefix('/voices/es-ES-Wavenet-B.mp3') },

  { name: 'de-DE: Mia', url: withPrefix('/voices/de-DE-Wavenet-A.mp3') },
  { name: 'de-DE: Ben', url: withPrefix('/voices/de-DE-Wavenet-B.mp3') },
]

class AudioExamples extends Component {
  state = {
    isSoundPrefetchEnabled: false,
    isPlaying: false,
    currentClip: null,
  }

  componentDidMount = () => {
    this.timer = setTimeout(
      () =>
        this.setState({
          isSoundPrefetchEnabled: true,
        }),
      5000
    )
  }

  componentWillUnmount = () => {
    if (!this.timer) return
    clearTimeout(this.timer)
  }

  disableLogging() {
    if (window.soundManager) window.soundManager.setup({ debugMode: false })
  }

  handlePlay = i => {
    this.disableLogging()
    const { isPlaying, currentClip } = this.state
    if (isPlaying && i === currentClip)
      return this.setState({
        isPlaying: false,
        currentClip: null,
      })
    this.setState({
      currentClip: i,
      isPlaying: true,
    })
  }

  handleFinishedPlaying = () => {
    this.setState({
      isPlaying: false,
      currentClip: null,
    })
  }

  handleMouseEnter = () => {
    const { isSoundPrefetchEnabled } = this.state
    if (isSoundPrefetchEnabled) return
    this.setState({ isSoundPrefetchEnabled: true })
  }

  renderSound = (sound, i) => {
    const { currentClip, isPlaying, isSoundPrefetchEnabled } = this.state
    const isCurrentPlaying = currentClip === i && isPlaying
    const iconUrl = isCurrentPlaying ? imgStop : imgPlay
    const buttonClasses = classnames({
      [styles.playerbutton]: true,
      [styles.playing]: isCurrentPlaying,
    })

    const SoundClip = (isCurrentPlaying || isSoundPrefetchEnabled) && (
      <Sound
        url={sound.url}
        playStatus={isCurrentPlaying ? 'PLAYING' : 'STOPPED'}
        onFinishedPlaying={this.handleFinishedPlaying}
      />
    )

    return (
      <div key={sound.name}>
        <button className={buttonClasses} onClick={() => this.handlePlay(i)}>
          <div className={styles.iconWrap}>
            <img src={iconUrl} alt="" />
          </div>
          <span>{sound.name}</span>
        </button>
        {SoundClip}
      </div>
    )
  }

  render() {
    return (
      <div className={styles.wrap}>
        <div
          onMouseEnter={this.handleMouseEnter}
          className={styles.playlist}
          role="presentation"
        >
          <h4>
            <ChevronDown /> Female
          </h4>
          <h4>
            <ChevronDown /> Male
          </h4>
          {sounds.map(this.renderSound)}
          <div className={styles.ribbon}>
            <Jump cascade delay={2000} duration={2000}>
              Try me!
            </Jump>
          </div>
        </div>
        <div className={styles.more}>
          <Link to="/voices">All Languages & Voices</Link>
        </div>
      </div>
    )
  }
}

export default AudioExamples
