import React from 'react'
import TextLoop from 'react-text-loop'
import styles from './Hero.module.sass'

const springConfig = {
  damping: 15,
  stiffness: 200,
}

const Hero = () => (
  <div className={styles.hero}>
    <div className={styles.content}>
      <div>
        <h2>
          Send me your favorite{' '}
          <TextLoop
            adjustingSpeed={100}
            mask={true}
            springConfig={springConfig}
          >
            <strong>Articles</strong>
            <strong>Blogs</strong>
            <strong>Pages</strong>
            <strong>Reports</strong>
            <strong>Journals</strong>
          </TextLoop>
          ,
          <br /> and I’ll read them to you.
          <small>Listen instantly using Podcasts.</small>
        </h2>
        <a href="https://my.tayl.app" className={styles.cta}>
          Get Started
          <small>Listen in 2 minutes</small>
        </a>
      </div>
    </div>
    <div className={styles.book} />
  </div>
)

export default Hero
