import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/Layout.js'
import Hero from '../components/Hero.js'
import Container from '../components/Container.js'
import AudioExamples from '../components/AudioExamples.js'
import Footer from '../components/Footer.js'

import imgBrowserExtensions from '../images/cleaning-cat.svg'
import imgApps from '../images/voice-id-smartphone.svg'
import imgIntegrations from '../images/module-three-1.svg'
import imgSubscribe from '../images/woman-phone.svg'
import imgBookmark from '../images/devices.svg'
import imgListen from '../images/woman-dog.svg'
import imgBrain from '../images/brain-chip.svg'
import imgHeadphones from '../images/headphones.svg'
import imgHeart from '../images/retouch-face.svg'
import imgMoon from '../images/weather-night-cloudy.svg'
import imgBrainMusic from '../images/music-genre-brain.svg'
import imgClock from '../images/steampunk-decoration.svg'
import imgGlobe from '../images/chat-translate.svg'
import imgReliableService from '../images/server-star.svg'
import imgSupport from '../images/professions-man-telecommunicator.svg'
import imgSecure from '../images/touch-id-lock.svg'
import imgPublic from '../images/calendar-favorite-heart-1.svg'
import imgStorage from '../images/cloud-music.svg'
import imgStories from '../images/stories.jpg'

import imgTheAtlantic from '../images/publishers/the-atlantic.svg'
import imgTheNewYorker from '../images/publishers/the-new-yorker.svg'
import imgTheNyt from '../images/publishers/nyt.svg'
import imgVanityFair from '../images/publishers/vanity-fair.svg'
import imgTheGuardian from '../images/publishers/the-guardian.svg'
import imgTheNextWeb from '../images/publishers/tnw.svg'
import imgPubBuzzfeedNeews from '../images/publishers/buzzfeed-news.svg'
import imgLifeHacker from '../images/publishers/lifehacker.svg'
import imgTime from '../images/publishers/time.svg'
import imgVice from '../images/publishers/vice.svg'
import imgWired from '../images/publishers/wired.svg'
import imgEngadget from '../images/publishers/engadget.svg'

import { numSupportedVoices, numSupportedLanguages } from '../constants.js'

import './index.sass'

const IndexPage = () => (
  <Layout>
    <Hero />

    <div className="section section--how" id="how">
      <Container>
        <h2 className="center">How it works</h2>

        <div className="row center">
          <div className="how-item col-xs-12 col-sm-4">
            <img src={imgSubscribe} alt="" />
            <div>
              <h3>1. Subscribe to Podcast</h3>
              <p>
                As a member you’ll get your very own personalized TAYL Podcast
                to subscribe to.
              </p>
            </div>
          </div>

          <div className="how-item col-xs-12 col-sm-4">
            <img src={imgBookmark} alt="" />
            <div>
              <h3>2. Bookmark anything</h3>
              <p>
                When you find something interesting online that you want read,
                simply bookmark it.
              </p>
            </div>
          </div>

          <div className="how-item col-xs-12 col-sm-4">
            <img src={imgListen} alt="" />
            <div>
              <h3>3. Listen instantly, or later</h3>
              <p>
                Bookmarks will be read and recorded instantly, and delivered to
                your podcast for listening.
              </p>
            </div>
          </div>
        </div>
      </Container>
    </div>

    <div className="section section--reasons" id="why">
      <div className="header-circle header-circle--reasons">
        <img src={imgHeadphones} title="Untz, untz, untz..." alt="" />
      </div>
      <Container>
        <h2 className="center">Four reasons to listen</h2>

        <div className="row">
          <div className="how-item col-sm-6 col-md-6">
            <div className="box">
              <h3>
                <img src={imgHeart} alt="" /> Improve your health
              </h3>
              <p>
                That lengthy article that you just have to read? Listen to it
                while on a nice walk outside. With TAYL you can avoid getting
                stuck staring at the screen for too long. Reduce eye-strain, get
                the blood pumping and feel better.
              </p>
            </div>
          </div>

          <div className="how-item col-sm-6 col-md-6">
            <div className="box">
              <h3>
                <img src={imgBrainMusic} alt="" /> Remember more
              </h3>
              <p>
                Immersion reading works by engaging multiple senses
                simultaneously. Listening in addition to reading fires off more
                neurons in your brain and creates deeper pathways, resulting in
                improved memory function.
              </p>
            </div>
          </div>

          <div className="how-item col-sm-6 col-md-6">
            <div className="box">
              <h3>
                <img src={imgMoon} alt="" /> Sleep better
              </h3>
              <p>
                Do you often spend time reading at the screen before going to
                bed? Blue light emitted from our screens is proven to disrupt
                our Circadian rhythm and reduce sleep quality. Switch off
                earlier, but keep on learning by ear.
              </p>
            </div>
          </div>

          <div className="how-item col-sm-6 col-md-6">
            <div className="box">
              <h3>
                <img src={imgClock} alt="" /> Save time
              </h3>
              <p>
                Instead of being glued to a screen for all your reading you can
                send some of it my way and you'll be able to listen on your
                commute, while working out or while having a bath. Listen at 2X
                the speed for extra efficiency!
              </p>
            </div>
          </div>
        </div>
      </Container>
      <div className="header-circle header-circle--listen">
        <img src={imgBrain} title="Beep, boop! Beep, boop!" alt="" />
      </div>
    </div>

    <div className="section section--listen" id="try">
      <Container>
        <h2 className="center">Machines never sounded so good</h2>

        <div className="row">
          <div className="how-item col-sm-7">
            <p>
              TAYL reads and delivers your articles faster than humanly possible
              by using state of the art machine learning. The resulting audio is
              very close to human performance, and will improve continuously.
              Listen to some of the voices with the{' '}
              <span role="img" aria-label="headphone">
                🎧
              </span>
              -buttons here or{' '}
              <a
                href="https://youtu.be/VEtr6pZBUrQ"
                target="_blank"
                rel="noreferrer"
              >
                on YouTube
              </a>
              .
            </p>
            <p>
              Select from {numSupportedVoices}+ voices in{' '}
              {numSupportedLanguages}+ languages with even more to come soon.
            </p>
            <p>Your content deserves a good voice!</p>
          </div>

          <div className="how-item col-sm-5">
            <AudioExamples />
          </div>
        </div>
      </Container>
    </div>

    <div className="section section--pricing" id="features">
      <Container>
        <div className="paper">
          <h2 className="center">
            Stories worth hearing
            <small>Listen to 100,000,000+ publications</small>
          </h2>

          <div className="stories">
            <img src={imgStories} alt="" className="imgStories" />
          </div>

          <div className="row publications">
            <div className="col-xs-6 col-sm-4 col-md-3 col-lg-3">
              <img src={imgTheAtlantic} alt="The Atlantic" />
            </div>
            <div className="col-xs-6 col-sm-4 col-md-3 col-lg-3">
              <img src={imgTheNewYorker} alt="The New Yorker" />
            </div>
            <div className="col-xs-6 col-sm-4 col-md-3 col-lg-3">
              <img src={imgTheNyt} alt="The New York Times" />
            </div>
            <div className="col-xs-6 col-sm-4 col-md-3 col-lg-3">
              <img src={imgVanityFair} alt="Vanity Fair" />
            </div>
            <div className="col-xs-6 col-sm-4 col-md-3 col-lg-3">
              <img src={imgTheGuardian} alt="The Guardian" />
            </div>
            <div className="col-xs-6 col-sm-4 col-md-3 col-lg-3">
              <img src={imgTheNextWeb} alt="The Next Web" />
            </div>
            <div className="col-xs-6 col-sm-4 col-md-3 col-lg-3">
              <img src={imgPubBuzzfeedNeews} alt="BuzzFeed.News" />
            </div>
            <div className="col-xs-6 col-sm-4 col-md-3 col-lg-3">
              <img src={imgLifeHacker} alt="LifeHacker" />
            </div>
            <div className="col-xs-6 col-sm-4 col-md-3 col-lg-3">
              <img src={imgTime} alt="TIME" />
            </div>
            <div className="col-xs-6 col-sm-4 col-md-3 col-lg-3">
              <img src={imgVice} alt="VICE" />
            </div>
            <div className="col-xs-6 col-sm-4 col-md-3 col-lg-3">
              <img src={imgWired} alt="Wired" />
            </div>
            <div className="col-xs-6 col-sm-4 col-md-3 col-lg-3">
              <img src={imgEngadget} alt="Engadget" />
            </div>
            <a className="cta-banner" href="https://my.tayl.app">
              Create your account
              <small>You'll be listening in a minute.</small>
            </a>
          </div>

          <div className="row">
            <div className="col-xs-12 col-sm-4">
              <h3>
                <img src={imgBrowserExtensions} alt="" /> Declutter the web
              </h3>
              <p>
                Get the browser extension for{' '}
                <a
                  href="https://chrome.google.com/webstore/detail/tayl-let-me-read-that-for/dnnlphagnbdccldakkjehjdideaobidd"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Chrome
                </a>{' '}
                and{' '}
                <a
                  href="https://addons.mozilla.org/en-US/firefox/addon/tayl-website-to-podcast/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Firefox
                </a>{' '}
                to clean websites from ads & clutter, so you can focus on what
                matters while reading.
              </p>
            </div>
            <div className="col-xs-12 col-sm-4">
              <h3>
                <img src={imgGlobe} alt="" /> {numSupportedVoices}+ voices in{' '}
                {numSupportedLanguages}+ languages
              </h3>
              <p>
                Listening is a matter of preference, so we give you the option
                to pick and choose from{' '}
                <Link to="/voices">a great variety of voices</Link> with even
                more to come.
              </p>
            </div>
            <div className="col-xs-12 col-sm-4">
              <h3>
                <img src={imgApps} alt="" /> There's an app for that
              </h3>
              <p>
                Get TAYL for{' '}
                <a
                  href="https://apps.apple.com/app/id1471504094?utm_source=tayl.app&utm_medium=landing&utm_campaign=features"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  iOS
                </a>{' '}
                or{' '}
                <a
                  href="https://play.google.com/store/apps/details?id=se.ultimatemachine.tayl&utm_source=tayl.app&utm_medium=landing&utm_campaign=features"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Android
                </a>{' '}
                to bring your content while on the move. The apps are free to
                download, just sign in to get started.
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-xs-12 col-sm-4">
              <h3>
                <img src={imgReliableService} alt="" /> Reliable & instant
                results
              </h3>
              <p>
                TAYL was built using only high-end premium components
                throughout. Expect quick results of amazing quality no matter
                what hour.
              </p>
            </div>
            <div className="col-xs-12 col-sm-4">
              <h3>
                <img src={imgIntegrations} alt="" /> 3,000+ integrations
              </h3>
              <p>
                Easily connect TAYL with GMail, Slack, Mailchimp, Typeform,
                Twitter and thousands of other apps via the{' '}
                <a
                  href="https://zapier.com/apps/tayl/integrations"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Zapier extension
                </a>
                . No coding required.
              </p>
            </div>
            <div className="col-xs-12 col-sm-4">
              <h3>
                <img src={imgStorage} alt="" /> Unlimited cloud storage
              </h3>
              <p>
                You do not have to think about storage space. Never delete any
                recording, unless you want to. Keep a full-text copy of
                everything you save.
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-xs-12 col-sm-4">
              <h3>
                <img src={imgSecure} alt="" /> Security & privacy
              </h3>
              <p>
                Your data on our service is encrypted using industry standards
                for cloud computing. We value user privacy and are GDPR
                compliant.
              </p>
            </div>
            <div className="col-xs-12 col-sm-4">
              <h3>
                <img src={imgPublic} alt="" /> Public roadmap
              </h3>
              <p>
                We believe in transparency and openly share our roadmap for the
                product, discuss all parts of development and share what we
                learn.
              </p>
            </div>
            <div className="col-xs-12 col-sm-4">
              <h3>
                <img src={imgSupport} alt="" /> Unrivalled customer service
              </h3>
              <p>
                Behind all these amazing machines is a very small team dedicated
                to improving the service by talking with you.
              </p>
            </div>
          </div>
        </div>
      </Container>
    </div>

    <Footer />

    {/* <div className="ph"> */}
    {/*   <a href="https://www.producthunt.com/posts/tayl?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-tayl" target="_blank" rel="noopener noreferrer"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=147134&theme=dark" alt="TAYL - Listen to websites as you would listen to podcasts. | Product Hunt Embed" styles="width: 250px; height: 54px;" width="250px" height="54px" /></a> */}
    {/* </div> */}
  </Layout>
)

export default IndexPage
